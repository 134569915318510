import mainProvider from "../providers/MainProvider";
import * as React from "react";

export const toTitleCase = (str) =>
  str
    .replace("_", " ")
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

export const requestNormalizer = (value = {}) => {
  return Object.fromEntries(
    Object.keys(value).map((valueKey) => {
      if (valueKey === "ids") {
        return [
          valueKey,
          JSON.stringify(
            value[valueKey]?.split(",").map((item) => parseInt(item)) ?? []
          ),
        ];
      } else {
        return [valueKey, value[valueKey]];
      }
    })
  );
};

export const getProviders = (config) => {
  const list = [];
  for (const element of config.PROJECTS_CONFIG) {
    let provider = new mainProvider(element.name);
    list.push({
      dataProvider: provider,
      resources: [provider.resources],
    });
  }
  return list;
};
