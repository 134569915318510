import config from "../config";
import {getProviders} from "../utils/common";

const Providers = getProviders(config);

export default (type, resource, params) => {
  const dataProviderMapping = Providers.find(dp => dp.resources.includes(resource));
  if (type === "GET_LIST") return dataProviderMapping.dataProvider.getList(resource, params);
  if (type === "GET_ONE") return dataProviderMapping.dataProvider.getOne(resource, params);
  if (type === "DELETE") return dataProviderMapping.dataProvider.delete(resource, params)
};
