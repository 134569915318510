/* eslint-disable */
import { API } from 'aws-amplify';
import {requestNormalizer} from "../utils/common";

class mainProvider {
    constructor(project) {
        this.project = project;
        this.resources = project + '-users';
        this.version = 3
    }
   async getList(resource, params) {
    params.filter = requestNormalizer(params.filter)
    const { pagination, filter, sort } = params;
    pagination.per_page = pagination.perPage;
    delete pagination.perPage;
    Object.keys(filter).map((filterKey) => {
      const filterValue = filter[filterKey];
      filter[filterKey] = Array.isArray(filterValue) ? filterValue.join() : filterValue;
    })

    const init = {
      queryStringParameters: {
        ...pagination,
        ...filter,
        ...sort
      },
    };

    const { items, _meta, cognito_group } = await API.get('admin', '/support/admin/users', {
        'headers': {
            'project': this.project,
            'version': this.version
        },
        'queryStringParameters': init.queryStringParameters
    });
     sessionStorage.setItem("cognito_group", cognito_group)
    return { data: items, total: _meta.total_items };
  }
    async getOne (resource, params) {
        const { id } = params;

        const data = await API.get('admin', '/support/admin/users/' + id, {
            'headers': {
                'project': this.project,
                'version': this.version
            }
        });
        return { data };
  }

  async delete(resource, params) {
        const { id } = params;
        return await API.del('admin', '/support/admin/users/' + id, {
            'headers': {
                'project': this.project,
                'version': this.version
            }
        });
    }
}

export default mainProvider;
