import config from "../../config";
import ProjectIcon from "../../theme/icon";
import {Admin, Resource} from "react-admin";
import Users, {UserShow} from "../../routes/Users";
import theme from "../../theme/Theme";
import authProvider from "../../providers/AuthProvider";
import dataProviders from "../../providers";
import NotFound from "../../NotFound";
import * as React from "react";


export const Main = () => {
    let resources = []
    for (const element of config.PROJECTS_CONFIG) {
        let icon = new ProjectIcon(element.icon).getIcon
        resources.push(<Resource
            icon={icon}
            name={element.resource_name}
            options={{label: element.label}}
            list={Users}
            show={UserShow}
        />)}
    return ( <Admin
      theme={theme}
      authProvider={authProvider}
      dataProvider={dataProviders}
      title="Support Admin"
      disableTelemetry catchAll={NotFound}
  >
      {resources}
  </Admin>
    )
}
