import React from 'react';
import {
    Show,
    SimpleShowLayout,
    Toolbar,
    DeleteWithConfirmButton,
    useRecordContext,
} from 'react-admin';
import {JsonField} from 'react-admin-json-view';

const DisabledButton = () => {
    const deletePermissionGroups = ["admin", "editor", "support"];
    const split_groups = sessionStorage.getItem("cognito_group").split(",")
    const filtredGroups  = deletePermissionGroups.filter(value => split_groups.includes(value))
    if (filtredGroups.length > 0) {
        return false;
    }
    else {
        return true;
    }
};
const DeleteButton = (props) => {
    const record = useRecordContext(props);
    return (<Toolbar
                style={{display: 'flex', justifyContent: 'flex-end'}}
                sx={{bgcolor: '#424242'}}
            >
                <DeleteWithConfirmButton
                    size='medium'
                    variant='contained'
                    color='error'
                    confirmColor='warning'
                    disabled={DisabledButton()}
                />
            </Toolbar>)
};

export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <DeleteButton/>
            <JsonField
                       source="data"
                       reactJsonOptions={{theme: "colors", name: null, displayDataTypes: false}}
            />
        </SimpleShowLayout>
    </Show>
);

export default UserShow;
