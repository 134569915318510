import { TextInput } from "react-admin";
import React from "react";

export const UserFilter = [
  <TextInput
    source="email"
    color="secondary"
    variant="filled"
    margin="normal"
    resettable
    alwaysOn
  />,
  <TextInput
    source="ids"
    color="secondary"
    variant="filled"
    margin="normal"
    placeholder="Ids separated by comma"
    resettable
    alwaysOn
  />,
];

export const UserFilterExtended = [
  ...UserFilter,
  <TextInput
    source="public_id"
    color="secondary"
    variant="filled"
    margin="normal"
    resettable
    alwaysOn
  />,
];
