import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#ffeb3b',
    },
    error: {
      main: '#eb4034'
    },
    type: "dark",
  },
});

export default theme;
