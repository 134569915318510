import React from "react";
import {
  TextField,
  BooleanField,
  DateField,
  EmailField,
  ShowButton,
  useResourceContext,
} from "react-admin";
import List from "../../components/List";
import { AnalyticButton } from "../../components/Button/AnalyticButton";
import { UserFilter, UserFilterExtended } from "../../components/Filter/Filter";

const Users = (props) => {
  const resourceName = useResourceContext();

  const filters = resourceName?.startsWith("yoga")
    ? UserFilterExtended
    : UserFilter;

  return (
    <List
      empty={false}
      {...props}
      filters={filters}
      sort={{ field: "created_at", order: "DESC" }}
      exporter={false}
    >
      <DateField label="CREATED AT" source="created_at" />
      <TextField label="ID" source="id" />
      <EmailField label="EMAIL" source="email" color="secondary" />
      <TextField label="NAME" source="name" />
      <TextField label="CREATED ON" source="platform" />
      <BooleanField label="PAID" source="is_paid" />
      <TextField label="PAYMENT PLATFORM" source="payment_platform" />
      <BooleanField label="ACCOUNT" source="has_account" />
      <TextField label="CLIENT PLATFORM" source="client_platform" />
      <TextField label="CLIENT VERSION" source="client_version" />
      <TextField label="COUNTRY" source="country" />
      <TextField label="LANGUAGE" source="language" />
      <BooleanField label="EXTRA PURCHASES" source="has_extra_purchases" />
      <ShowButton label="DETAILS" color="secondary" variant="contained" />
      <AnalyticButton label="ANALYTICS" source="id" />
    </List>
  );
};

export default Users;
