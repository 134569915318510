import {Button, useRecordContext, useRedirect} from "react-admin";
import React from "react";

export const AnalyticButton = (props) => {
    const record = useRecordContext(props);

    if (record.amplitude_url === null) {
        return (
        <Button disabled={true} label={props.label} variant="contained" />
        )}
    else {
        const handleClick = () => {
            window.open(record.amplitude_url);
        }
        return (
        <Button label={props.label} color="secondary" variant="contained" onClick={handleClick} />
        )}
};
