import * as React from "react";

class ProjectIcon {
    constructor (url) {
        this.url = url;
    }

    getIcon = () => {
        return <img width="24" height="24" src={this.url}/>
    }
}

export default ProjectIcon;
